import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@xpo-ltl/sdk-common';
import { OffBillIncentiveApiService } from '@xpo-ltl/sdk-offbillincentive';
import { ListPricingAnalystsResp, PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppActions } from '../../enums/app-actions.enum';
import { AppRoutes } from '../../enums/app-routes.enum';
import { Util } from '../../enums/util.enum';
import { PricingWorkbenchService } from '../pricing-workbench.service';

export class AppRoles {
  static CODES = {
    21: 'OBI Auditor',
    22: 'OBI Builder',
    23: 'OBI Viewer',
    99: 'IT',
  };
}

@Injectable({
  providedIn: 'root',
})
export class AppRoleService {
  userData: any;
  pricingCodes: any;
  actions: any;
  firstTime = true;
  roleNameObs = new BehaviorSubject<string[]>([]);
  enabledActionObs = new BehaviorSubject<PricingCode[]>([]);

  constructor(
    private pricingWorkbenchService: PricingWorkbenchService,
    private router: Router,
    private loginService: OffBillIncentiveApiService
  ) {}

  setUser(user: any) {
    this.userData = user;
  }

  getRoleName() {
    return this.roleNameObs;
  }

  getUser() {
    return this.userData;
  }

  setActions(actions: any) {
    if (!this.actions) {
      this.actions = actions;
    }
    if (!actions || !actions.length) {
      this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
    }
  }

  getActions() {
    return this.actions || [];
  }

  getServiceUserData() {
    const user = this.getUser();
    if (user) {
      return new Observable<any>((observer) => {
        observer.next(user);
      });
    } else {
      return this.loginService.loggedInUser();
    }
  }

  buildRolesList(roleTypes: string[]): string[] {
    return roleTypes.reduce((roles: string[], curr: string) => {
      if (!curr) {
        return roles;
      }
      curr.split(',').forEach((type) => {
        const role = type ? type.trim() : null;
        if (role && !roles.includes(role)) {
          roles.push(role);
        }
      });
      return roles;
    }, []);
  }

  setRoleNamesByCode(codes: string[]): string[] {
    const names = [];
    Object.entries(AppRoles.CODES).forEach(([code, name]) => {
      if (codes.includes(code)) {
        names.push(name);
      }
    });
    return names;
  }

  getPermission(componentName: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.getServiceUserData().subscribe(
        (response: User) => {
          if (response) {
            const userData = response;
            this.pricingWorkbenchService
              .getPricingAnalystDetails(userData.employeeId)
              .subscribe((analyst: ListPricingAnalystsResp) => {
                const employeeRoleType = analyst.pricingAnalysts[0].employeeRoleType;
                const additionalRoleTypes = analyst.pricingAnalysts[0].additionalRoleType;
                const roles = this.buildRolesList([employeeRoleType, additionalRoleTypes]);
                const roleNames = this.setRoleNamesByCode(roles);

                this.pricingWorkbenchService
                  .requestObiPricingCodes()
                  .pipe(filter((permissions) => permissions !== undefined))
                  .subscribe((codes: PricingCode[]) => {
                    const allowed = codes.filter((permission) => {
                      return roles.includes(permission.code) && permission.value === Util.shortYes;
                    });

                    this.setActions(allowed);

                    const action = this.getActionsByCode(this.getActionByComponentName(componentName));

                    this.enabledActionObs.next(allowed);
                    this.roleNameObs.next(roleNames);
                    observer.next(!!action);
                  });
              });
          } else {
            observer.next(false);
          }
        },
        (error) => {
          observer.next(false);
        }
      );
    });
  }

  getActionByComponentName(data: any) {
    switch (data) {
      case 'ObiAgreementListComponent':
        return AppActions.agreement_list;
      case 'ObiAgreementDetailTabsComponent':
        return AppActions.agreement_detail;
      case 'ObiAgreementDetailComponent':
        return AppActions.agreement_detail;
      case 'ObiAgreementLocationComponent':
        return AppActions.agreement_location;
      case 'ObiAgreementDetailHistoryComponent':
        return AppActions.agreement_history;
      case 'ObiAgreementProgramDetailTabsComponent':
        return AppActions.program_detail;
      case 'ObiAgreementProgramDetailComponent':
        return AppActions.program_detail;
      case 'ObiAgreementProgramLocationComponent':
        return AppActions.program_location;
      case 'ObiIncentiveListComponent':
        return AppActions.incentive_list;
      case 'ObiIncentiveDetailTabsComponent':
        return AppActions.incentive_detail;
      case 'ObiIncentiveDetailComponent':
        return AppActions.incentive_detail;
      case 'ObiIncentiveShipmentsComponent':
        return AppActions.incentive_shipment_list;
      case 'ObiIncentiveDetailLocationComponent':
        return AppActions.incentive_list_location;
      default:
      // code block
    }
  }

  getActionsByCode(code) {
    if (this.actions) {
      return this.actions.find((data) => {
        return data.name === code;
      });
    } else {
      return false;
    }
  }

  getEnableActionByCode(code) {
    if (this.actions) {
      return !!this.actions.find((data) => {
        return data.name === code;
      });
    }
  }
}
