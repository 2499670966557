<xpo-shell class="app-obi-container">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name
    >OBI <span *ngIf="!isProduction" style="color: crimson"> ( {{ this.region }} )</span></xpo-header-sub-app-name
  >
  <xpo-header-navigation>
    <button
      *ngIf="isMenuAllowed(menuItems.offBillIncentiveList)"
      routerLink="/off-bill-incentive/list-page"
      routerLinkActive="xpo-selected"
    >
      Off Bill Incentive List
    </button>
    <button
      *ngIf="isMenuAllowed(menuItems.agreementList)"
      routerLink="/obi-agreement/list-page"
      routerLinkActive="xpo-selected"
    >
      Agreement List
    </button>
  </xpo-header-navigation>
  <xpo-header-utilities-actions>
    <app-notification-menu *ngIf="populateAccountPopover()"> </app-notification-menu>
    <xpo-auth-user-profile>
      <h4 class="role-list">{{ roleList }}</h4>
      <div class="profile-link">
        <a [href]="customerBaseURL" target="_blank">Edge cBase</a>
        <a [href]="shipmentManagementURL" target="_blank">Edge Shipment Management</a>
        <a [href]="ratingURL" target="_blank">Edge Rating Analysis</a>
      </div>
    </xpo-auth-user-profile>
  </xpo-header-utilities-actions>

  <router-outlet></router-outlet>
</xpo-shell>
<xpo-footer xpoFooterSticky [version]="appVersion">
  <div>
    <a [href]="releaseNotesURL" target="_blank">Release Notes</a>
  </div>
</xpo-footer>

<app-notification></app-notification>
