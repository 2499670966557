import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { AppRoutes } from '../../../enums/app-routes.enum';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-obi-incentive-id-cell-renderer',
  templateUrl: './obi-incentive-id-cell-renderer.component.html',
  styleUrls: ['./obi-incentive-id-cell-renderer.component.scss'],
})
export class ObiIncentiveIdCellRendererComponent implements ICellRendererAngularComp {
  obiIncentiveId: number;
  obiIncentiveNbr: number;
  detailPageUrl: string;
  params;

  constructor(
    private helperService: HelperService,
    private router: Router
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!this.isPinnedRow()) {
      if (params.data.agreement?.incentive?.length > 0) {
        this.obiIncentiveId = this.helperService.currentIncentive(params.data.agreement.incentive, 'obiIncentiveId');
        this.obiIncentiveNbr = this.helperService.currentIncentive(params.data.agreement.incentive, 'incentiveNbr');
      } else {
        this.obiIncentiveId = params.data.obiIncentiveId;
        this.obiIncentiveNbr = params.data.incentiveNbr;
      }
      this.detailPageUrl = `${AppRoutes.OFF_BILL_INCENTIVE}/${AppRoutes.DETAIL_PAGE}/${this.obiIncentiveId}`;
    }
  }

  isPinnedRow() {
    return this.params.node.rowPinned;
  }

  redirect(openNew = false) {
    if (openNew) {
      window.open(this.detailPageUrl, '_blank');
    } else if (this.router.url.includes(`${AppRoutes.OBI_AGREEMENT}`)) {
      this.router.navigateByUrl(this.detailPageUrl);
    } else {
      // Implies the incentive detail tray is open and rerouting is not needed
      // do nothing
    }
  }

  refresh(): boolean {
    return false;
  }
}
