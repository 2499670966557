import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoNgxGridModule } from '@xpo-ltl/ngx-grid';
import { XpoChipsModule } from '@xpo-ltl/ngx-ltl-core/chips';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { BooleanCellRendererComponent } from './cell-renderers/boolean-cell-renderer/boolean-cell-renderer.component';
import { CurrencyCellRendererComponent } from './cell-renderers/currency-cell-renderer/currency-cell-renderer.component';
import { StatusCellRendererComponent } from './cell-renderers/status-cell-renderer/status-cell-renderer.component';
import { BulkResultComponent } from './components/bulk-result/bulk-result.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { GridAppliedFiltersComponent } from './components/grid/grid-applied-filters/grid-applied-filters.component';
import { AppliedFiltersDisplayPipe } from './components/grid/grid-applied-filters/helper/applied-filter.pipe';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ag-grid';
import { XpoGridToolbarModule } from '@xpo-ltl/ngx-grid';
import { MatButtonModule } from '@angular/material/button';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { AgGridModule } from 'ag-grid-angular';
import { GridProgramsCellRendererComponent } from './cell-renderers/obi-incentive-programs-cell-renderer/grid-programs-cell-renderer.component';
import { NotAuthorizedComponent } from './dialog/not-authorized/not-authorized.component';
import { BackdateDialogComponent } from './dialog/backdate-dialog/backdate-dialog.component';
import { ExpireDialogComponent } from './dialog/expire-dialog/expire-dialog.component';
import { ErrorDialogComponent } from './dialog/error-dialog/error-dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ObiIncentiveIdCellRendererComponent } from './cell-renderers/obi-incentive-id-cell-renderer/obi-incentive-id-cell-renderer.component';

@NgModule({
  declarations: [
    // Components
    BulkResultComponent,
    ProgressBarComponent,
    GridProgramsCellRendererComponent,
    CurrencyCellRendererComponent,
    BooleanCellRendererComponent,
    StatusCellRendererComponent,
    ObiIncentiveIdCellRendererComponent,
    GridAppliedFiltersComponent,
    BackdateDialogComponent,
    ExpireDialogComponent,
    NotAuthorizedComponent,
    ErrorDialogComponent,
    // Pipes/Directives
    AppliedFiltersDisplayPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    XpoIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatChipsModule,
    XpoChipsModule,
    XpoNgxGridModule,
    XpoStatusIndicatorModule,
    MatTooltipModule,
    XpoAgGridModule,
    XpoGridToolbarModule,
    MatButtonModule,
    XpoButtonModule,
    XpoDialogModule,
    MatDatepickerModule,
    MatInputModule,
    AgGridModule.withComponents([]),
    // Pipes/Directives
  ],
  exports: [
    // Components
    BulkResultComponent,
    ProgressBarComponent,
    GridProgramsCellRendererComponent,
    GridAppliedFiltersComponent,
    // Pipes/Directives
    AppliedFiltersDisplayPipe,
  ],
  // providers:
})
export class SharedModule {}
