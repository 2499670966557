import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@xpo-ltl/data-api';
import { AuditInfo, ListInfo } from '@xpo-ltl/sdk-common';
import {
  BulkUpdateIncentiveAggregatePeriodsPath,
  BulkUpdateIncentiveAggregatePeriodsRqst,
  BulkUpdateIncentiveShipmentsPath,
  BulkUpdateIncentiveShipmentsRqst,
  BulkUpdateProgramCustomerLocationsPath,
  BulkUpdateProgramCustomerLocationsRqst,
  CreateProgramCustomerLocationPath,
  CreateProgramCustomerLocationRqst,
  CustomerIncentiveLocation,
  DeleteIncentivePath,
  DeleteProgramCustomerLocationPath,
  GetIncentivePath,
  GetIncentiveResp,
  Incentive,
  IncentiveAggregatePeriod,
  IncentiveLocation,
  IncentiveProgram,
  IncentiveShipment,
  IncentiveWithBatchControl,
  ListCustomerIncentiveLocationsPath,
  ListCustomerIncentiveLocationsQuery,
  ListCustomerIncentiveLocationsResp,
  ListIncentiveBatchControlsResp,
  ListIncentiveShipmentsResp,
  ListIncentiveShipmentsRqst,
  ListIncentivesQuery,
  ListIncentivesResp,
  OffBillIncentiveApiService,
  OffBillIncentiveFilter,
  ProcessIncentiveResp,
  ProcessIncentiveRqst,
  UpdateIncentiveCommentResp,
  UpdateIncentiveCommentRqst,
  UpdateIncentiveLocationsPath,
  UpdateIncentiveLocationsRqst,
  UpdateIncentivePath,
  UpdateIncentiveProgramPath,
  UpdateIncentiveProgramRqst,
  UpdateIncentiveRqst,
} from '@xpo-ltl/sdk-offbillincentive';
import { ColumnState } from 'ag-grid-community';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs/index';
import { map, shareReplay } from 'rxjs/internal/operators';
import { AgreementActionType } from '../../enums/agreement/agreementActionType.enum';
import { ObiQueryParams } from '../../enums/incentive/obi-query-params.enum';
import { AppRoutes } from '../../enums/app-routes.enum';
import { Util } from '../../enums/util.enum';
import { HelperService } from '../helper.service';
import { ListPricingAnalystsQuery } from '@xpo-ltl/sdk-pricingworkbench';
import { PricingWorkbenchService } from '../pricing-workbench.service';
import { IncentiveOwnerInfo } from '../../models/incentiveOwner.model';
import { IncentiveStatusCd } from '../../enums/incentive/incentive-status-code.enum';
import { NotificationMenuItem } from '../../models/notification-menu-item.model';
import { NotificationStateService } from '../notification-state.service';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class ObiApiService {
  private destroySubjects = {
    list: new Subject<void>(),
    tabs: new Subject<void>(),
    detail: new Subject<void>(),
  };
  private obiIncentiveListCache$: Observable<Array<Incentive>>;
  // incentiveAnalyst$ = new BehaviorSubject<any>([]);
  incentiveOwnerList: IncentiveOwnerInfo[] = [
    {
      employeeId: 'TOB00070',
      employeeName: 'Accounts Payable Process',
    },
    {
      employeeId: 'OBIBATCH',
      employeeName: 'OBI Batch',
    },
  ];
  incentiveListQueryParams: ListIncentivesQuery = new ListIncentivesQuery();
  incentiveListQueryCache: ListIncentivesQuery;
  incentivePros: Partial<Incentive>;
  incentiveDiscount: number = 0;
  batchLength = 50;
  activeFilters = false;
  refreshDataSurce: EventEmitter<boolean> = new EventEmitter<boolean>();
  filters: ListIncentivesQuery;
  obiIncentiveId: number;
  obiIncentiveOwner: string;
  obiRunStatusCd: string;
  listInfo: ListInfo;

  protected columnState: ColumnState[];
  @Output() deleteIncentiveEmitter = new EventEmitter();
  constructor(
    public offBillIncentiveApiService: OffBillIncentiveApiService,
    private notificationStateService: NotificationStateService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private router: Router,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {}

  deleteEmitter(obiIncentiveId) {
    this.deleteIncentiveEmitter.next(obiIncentiveId);
    //this.setObiIncentiveId(null);
  }

  setColumnState(state: ColumnState[]) {
    this.columnState = state;
  }

  getColumnState() {
    return this.columnState;
  }

  requestIncentiveList(): Observable<Incentive[]> {
    this.incentiveListQueryCache = this.getIncentiveListQueryParams();
    return this.offBillIncentiveApiService.listIncentives(this.incentiveListQueryCache).pipe(
      map((incentiveListResp: ListIncentivesResp) => {
        const incentiveList = [];
        incentiveListResp.incentiveWithBatchControls.forEach((result: any) => {
          /**
           *  catch for incentive with out run status
           *  result.incentive.runStatusCd = result.incentiveBatchControl? result.incentiveBatchControl.runStatusCd: 'PROCESSED';
           **/

          result.incentive.customerNameOnCheck = this.helperService.removeExtraWhiteSpaces(
            result.incentive.customerNameOnCheck
          );

          //result.incentive.incentiveOwner = result.incentive.auditInfo.updateById;
          // result.incentive.incentiveOwner = result.incentive.incentiveOwner
          //   ? result.incentive.incentiveOwner
          //   : result.incentive.auditInfo.updateById
          //     ? result.incentive.auditInfo.updateById
          //     : result.incentive.auditInfo.updateByPgmId;

          result.incentive.completedDate = result.incentive.auditInfo.updatedTimestamp;

          result.incentive.runStatusCd = result.incentiveBatchControl
            ? result.incentiveBatchControl.runStatusCd
            : 'SUCCESS';

          result.incentive.totalProsDiscountedNbr = result.incentive.totalProsDiscountedNbr
            ? result.incentive.totalProsDiscountedNbr
            : 0;
          result.incentive.totalProsPendingNbr = result.incentive.totalProsPendingNbr
            ? result.incentive.totalProsPendingNbr
            : 0;
          result.incentive.totalProsRejectedNbr = result.incentive.totalProsRejectedNbr
            ? result.incentive.totalProsRejectedNbr
            : 0;

          result.incentive.totalProsNbr =
            result.incentive.totalProsDiscountedNbr +
            result.incentive.totalProsPendingNbr +
            result.incentive.totalProsRejectedNbr;

          incentiveList.push(result.incentive);
        });

        return incentiveList;
      })
    );
  }

  requestIncentiveShipmentList(
    filter: OffBillIncentiveFilter[],
    listInfo: ListInfo
  ): Observable<ListIncentiveShipmentsResp> {
    const request = new ListIncentiveShipmentsRqst();
    request.incentiveShipmentFilters = filter;
    request.listInfo = listInfo;

    return this.offBillIncentiveApiService.listIncentiveShipments(request).pipe(
      map((resp: ListIncentiveShipmentsResp) => {
        resp.incentiveShipmentWithShipments.map((response: any) => {
          this.getShipmentCustomerType(response);
          response.shipment.oracleCollectAmount = response.shipment.paymentAmount
            ? response.shipment.paymentAmount
            : '';
          response.shipment.collectAmount = response.shipment.netRevenueAmount
            ? response.shipment.netRevenueAmount
            : '';
          response.obiIncentiveShipmentId = response.incentiveShipment.obiIncentiveShipmentId;
          response.adjustmentAmount = response.incentiveShipment.adjustmentAmount;
          response.adjustedRate = response.incentiveShipment.adjustedRate;
          response.qualifiedInd = response.incentiveShipment.qualifiedInd;
          response.incentiveShipment.discountAmount =
            response.incentiveShipment.statusCd !== Util.rejectedCd ? response.incentiveShipment.discountAmount : 0;
        });
        return resp;
      })
    );
  }

  async getIncentiveOwner(incentive: Incentive): Promise<IncentiveOwnerInfo> {
    incentive.incentiveOwner = incentive.incentiveOwner
      ? incentive.incentiveOwner
      : incentive.auditInfo.updateById
        ? incentive.auditInfo.updateById
        : incentive.auditInfo.updateByPgmId;

    let incentiveOwnerFound: IncentiveOwnerInfo = this.incentiveOwnerList.find((incentiveOwner) => {
      return incentiveOwner.employeeId === incentive.incentiveOwner;
    });

    if (
      !incentiveOwnerFound &&
      incentive.incentiveOwner &&
      incentive.statusCd != IncentiveStatusCd.PENDING.toUpperCase()
    ) {
      let newIncentiveOwner: IncentiveOwnerInfo = {
        employeeId: incentive.incentiveOwner,
        employeeName:
          incentive.incentiveOwner === 'TOB00070'
            ? 'Accounts Payable Process'
            : await this.getPricingAnalystName(incentive.auditInfo),
      };
      this.incentiveOwnerList.push(newIncentiveOwner);
      return newIncentiveOwner;
    } else {
      return incentiveOwnerFound;
    }
  }

  setIncentiveListQueryParams(params: ListIncentivesQuery): void {
    this.incentiveListQueryParams = params;
    this.filters = params;
  }

  clearIncentiveListQueryParams() {
    this.incentiveListQueryParams = new ListIncentivesQuery();
  }

  backToObiListPage() {
    this.activeFilters = true;
    this.router.navigate([`${AppRoutes.OFF_BILL_INCENTIVE}/${AppRoutes.LIST_PAGE}`]);
  }

  getIncentiveListQueryParams(): ListIncentivesQuery {
    this.incentiveListQueryParams.incentiveType =
      this.incentiveListQueryParams.periodBeginDate ||
      this.incentiveListQueryParams.periodEndDate ||
      this.incentiveListQueryParams.customerName ||
      this.incentiveListQueryParams.madCd
        ? null
        : ObiQueryParams.CURRENT_PAY_PERIOD;
    return this.incentiveListQueryParams;
  }

  getIncentiveList(noValidate?: boolean): Observable<Incentive[]> {
    this.notificationService.hideOverlayMessage();
    if (!this.obiIncentiveListCache$ || this.incentiveListQueryCache !== this.incentiveListQueryParams || noValidate) {
      this.obiIncentiveListCache$ = this.requestIncentiveList().pipe(shareReplay());
    }
    return this.obiIncentiveListCache$;
  }

  setObiIncentiveId(id) {
    this.obiIncentiveId = id;
  }

  getObiIncentiveId() {
    return this.obiIncentiveId;
  }

  setObiIncentiveOwner(owner) {
    this.obiIncentiveOwner = owner;
  }

  setObiRunStatusCd(status) {
    this.obiRunStatusCd = status;
  }

  setListInfo(listInfo) {
    this.listInfo = listInfo;
  }

  requestIncentiveDetail(obiIncentiveId): Observable<GetIncentiveResp> {
    const pathParams = new GetIncentivePath();
    if (obiIncentiveId) {
      pathParams.obiIncentiveId = obiIncentiveId;
    } else {
      pathParams.obiIncentiveId = this.obiIncentiveId;
    }
    return this.offBillIncentiveApiService.getIncentive(pathParams);
  }

  async getPricingAnalystName(auditInfo: AuditInfo): Promise<string> {
    const params = new ListPricingAnalystsQuery();
    params.employeeId = auditInfo.updateById || auditInfo.updateByPgmId || '';
    params.levelOfDetail = 'Security';
    const response = await this.pricingWorkbenchService
      .getPricingAnalystDetailsFromAuditInfo(params, auditInfo)
      .toPromise();
    return response.employeeFullName;
  }

  requestIncentiveLocation(obiIncentiveId, programId): Observable<CustomerIncentiveLocation[]> {
    const queryParams = new ListCustomerIncentiveLocationsQuery();
    queryParams.obiProgramId = programId;
    const pathParams = new ListCustomerIncentiveLocationsPath();
    pathParams.obiIncentiveId = obiIncentiveId;
    return this.offBillIncentiveApiService.listCustomerIncentiveLocations(pathParams, queryParams).pipe(
      map((response: ListCustomerIncentiveLocationsResp) => {
        response.customerIncentiveLocations.forEach((data: any) => {
          data.acctId = {};
          data.acctId.acctName = this.helperService.removeExtraWhiteSpaces(data.acctId?.acctName) || undefined;
          data.obiIncentiveLocationId = data.incentiveLocation.obiIncentiveLocationId;
          data.incentiveLocation.adjustmentAmount = !!data.incentiveLocation.adjustmentAmount
            ? data.incentiveLocation.adjustmentAmount
            : 0;
        });
        return response.customerIncentiveLocations;
      })
    );
  }

  updateAdjustmentBulk(incentiveLocations: IncentiveLocation[], incentiveId: number): Observable<void> {
    const request = new UpdateIncentiveLocationsRqst();
    request.incentiveLocations = incentiveLocations;
    const pathParams = new UpdateIncentiveLocationsPath();
    pathParams.obiIncentiveId = incentiveId;
    return this.offBillIncentiveApiService.updateIncentiveLocations(request, pathParams);
  }

  updateIncentiveShipments(actionType: string, incentiveShipments: IncentiveShipment[], incentiveId: number) {
    const request = new BulkUpdateIncentiveShipmentsRqst();
    request.actionType = actionType;
    request.incentiveShipments = incentiveShipments;
    const pathParams = new BulkUpdateIncentiveShipmentsPath();
    pathParams.obiIncentiveId = incentiveId;
    const dataOption = { toastOnError: false };
    return this.offBillIncentiveApiService.bulkUpdateIncentiveShipments(request, pathParams, dataOption);
  }

  updateIncentiveAggregatePeriods(aggregatePeriods: IncentiveAggregatePeriod[], programId) {
    const request = new BulkUpdateIncentiveAggregatePeriodsRqst();
    const pathParams = new BulkUpdateIncentiveAggregatePeriodsPath();

    request.incentiveAggregatePeriods = aggregatePeriods;
    pathParams.obiIncentiveProgramId = programId;

    return this.offBillIncentiveApiService.bulkUpdateIncentiveAggregatePeriods(request, pathParams);
  }

  deleteObiIncentive(obiIncentiveId: number) {
    const request = new DeleteIncentivePath();
    request.obiIncentiveId = obiIncentiveId;
    const dataOption = { toastOnError: false };
    return this.offBillIncentiveApiService.deleteIncentive(request, dataOption);
  }

  deleteObiProgramLocation(obiProgramLocationId, programId) {
    const request = new DeleteProgramCustomerLocationPath();
    request.obiProgramLocationId = obiProgramLocationId;
    request.obiProgramId = programId;
    const dataOption = { toastOnError: false, loadingOverlayEnabled: true };
    return this.offBillIncentiveApiService.deleteProgramCustomerLocation(request, dataOption);
  }

  createObiProgramLocation(obiProgramLocation, obiProgramId) {
    const request = new CreateProgramCustomerLocationRqst();
    request.programCustomerLocation = obiProgramLocation;
    const pathParams = new CreateProgramCustomerLocationPath();
    pathParams.obiProgramId = obiProgramId;
    const dataOption = { toastOnError: false, loadingOverlayEnabled: true };
    return this.offBillIncentiveApiService.createProgramCustomerLocation(request, pathParams, dataOption);
  }

  updateObiProgramLocation(obiProgramLocation, obiProgramId, type?: any) {
    const request = new BulkUpdateProgramCustomerLocationsRqst();
    request.actionType = type ? type : AgreementActionType.CHANGE;
    if (obiProgramLocation.length) {
      request.programCustomerLocations = obiProgramLocation;
    } else {
      request.programCustomerLocations = [];
      request.programCustomerLocations.push(obiProgramLocation);
    }
    const pathParams = new BulkUpdateProgramCustomerLocationsPath();
    pathParams.obiProgramId = obiProgramId;
    const dataOption = { toastOnError: false, loadingOverlayEnabled: true };
    return this.offBillIncentiveApiService.bulkUpdateProgramCustomerLocations(request, pathParams, dataOption);
  }

  updateIncentiveHeaderDetail(data: Incentive, obiIncentiveId: number, actionType: string) {
    const request = new UpdateIncentiveRqst();
    request.incentive = data;
    request.actionType = actionType;
    const pathParams = new UpdateIncentivePath();
    pathParams.obiIncentiveId = obiIncentiveId;
    const dataOption = { toastOnError: false, loadingOverlayEnabled: true };
    return this.offBillIncentiveApiService.updateIncentive(request, pathParams, dataOption);
  }

  updateIncentiveProgram(incentiveProgram: IncentiveProgram, obiIncentiveId: number) {
    const request = new UpdateIncentiveProgramRqst();
    request.incentiveProgram = incentiveProgram;
    request.obiIncentiveId = obiIncentiveId;
    const pathParams = new UpdateIncentiveProgramPath();
    pathParams.obiIncentiveProgramId = incentiveProgram.obiIncentiveProgramId;
    const dataOption = { toastOnError: false, loadingOverlayEnabled: true };
    return this.offBillIncentiveApiService.updateIncentiveProgram(request, pathParams, dataOption);
  }

  updateIncentiveComment(data: Incentive): Observable<UpdateIncentiveCommentResp> {
    const request = new UpdateIncentiveCommentRqst();
    request.incentive = data;

    return this.offBillIncentiveApiService.updateIncentiveComment(request);
  }

  createOffBillIncentive(data: ProcessIncentiveRqst): Observable<ProcessIncentiveResp> {
    const request = data;
    const dataOption = { toastOnError: false, loadingOverlayEnabled: true };
    return this.offBillIncentiveApiService.processIncentive(request, dataOption);
  }

  getShipmentCustomerType(element: any): void {
    if (element.shipment.shipperCustomerNbr === element.incentiveLocation.locationInstId) {
      element.shipment.shipmentType = 'S'; // Shipper
    } else if (element.shipment.consigneeCustomerNbr === element.incentiveLocation.locationInstId) {
      element.shipment.shipmentType = 'C'; // Consignee
    } else if (element.shipment.billtoCustomerNbr === element.incentiveLocation.locationInstId) {
      element.shipment.shipmentType = 'B'; // Bill-To
    } else if (element.shipment.billtoCustomerNbr === element.incentiveLocation.locationInstId) {
      element.shipment.shipmentType = 'T'; // 3rd Party
    }
  }

  getBatchIncentives(): Observable<ListIncentiveBatchControlsResp> {
    const dataOption = { toastOnError: false, loadingOverlayEnabled: false };
    return this.offBillIncentiveApiService.listIncentiveBatchControls(dataOption);
  }

  getNotifications = () => {
    this.getBatchIncentives().subscribe((response: ListIncentiveBatchControlsResp) => {
      let notifications = [];
      response.incentiveWithBatchControls?.forEach((element: IncentiveWithBatchControl) => {
        notifications.push(
          new NotificationMenuItem(
            element.incentive.incentiveNbr.toString(),
            element.incentive.madCode,
            element.incentive.customerNameOnCheck,
            '-',
            element.incentiveBatchControl.runStatusCd === Util.failCd,
            element.incentive
          )
        );
      });
      this.notificationStateService.setNotifications(notifications);
    });
  };

  setIncentivePros(incentive: Incentive) {
    this.incentivePros = {
      totalProsDiscountedNbr: incentive.totalProsDiscountedNbr || 0,
      totalProsPendingNbr: incentive.totalProsPendingNbr || 0,
      totalProsRejectedNbr: incentive.totalProsRejectedNbr || 0,
    };
  }

  setDiscountAmount(incentive: Incentive) {
    this.incentiveDiscount = incentive.discountAmount;
  }

  calculateTotalPros(incentivePros: Partial<Incentive>): number {
    return (
      (incentivePros.totalProsDiscountedNbr || 0) +
      (incentivePros.totalProsPendingNbr || 0) +
      (incentivePros.totalProsRejectedNbr || 0)
    );
  }

  unsubscribeFromParent(name) {
    this.destroySubjects[name].next();
  }

  getDestroyObservable(name): Observable<void> {
    return this.destroySubjects[name].asObservable();
  }
}
