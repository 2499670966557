import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { Util } from '../enums/util.enum';

@Injectable({
  providedIn: 'root',
})
export class FormatDateService {
  constructor(private datePipe: DatePipe) {}

  formattingDate(date) {
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 34, 56, 789)
    ).toISOString();
  }

  formatDatePicker(date) {
    return new Date(date);
  }

  transformDate(date: any) {
    return new Date(this.datePipe.transform(date, Util.dateFormat));
  }

  transformDateTime(date) {
    return moment(moment(date).tz(Util.PST_TIMEZONE).format(Util.DATE_FORMAT_TIME), 'MM-DD-YYYY').utc(true).toDate();
  }

  getTimeStamp(date: any): string {
    return typeof date === 'object' ? moment(date).format('x') : date;
  }

  get lastPeriodDates() {
    const priorMonth = moment().subtract(1, 'months');
    return {
      begin: priorMonth.startOf('month').toDate(),
      end: priorMonth.endOf('month').toDate(),
    };
  }
}
