import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SdkCustomerModule } from '@xpo-ltl-2.0/sdk-customer';
import { ConfigManagerModule } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XpoGridToolbarModule, XpoNgxGridModule } from '@xpo-ltl/ngx-grid';
import { XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ag-grid';
import { XpoLtlPopoverModule } from '@xpo-ltl/ngx-ltl-core/xpo-ltl-popover';
import { SdkOffBillIncentiveModule } from '@xpo-ltl/sdk-offbillincentive';
import { SdkPricingModule } from '@xpo-ltl/sdk-pricing';
import { SdkPricingWorkbenchModule } from '@xpo-ltl/sdk-pricingworkbench';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { NotificationComponent } from './notification/notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { CoreModule } from './core/core.module';
import { AppRoleService } from './services/user-role/app-role.service';
import { NotificationMenuComponent } from './shared/components/notification-menu/notification-menu.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ResultDialogComponent } from './obi/components/obi-incentive-detail-tabs/obi-incentive-shipments/result-dialog/result-dialog.component';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');
  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}
@NgModule({
  declarations: [AppComponent, NotificationComponent, ResultDialogComponent, NotificationMenuComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatAutocompleteModule,
    XpoLtlAuthUiModule,
    XpoShellModule,
    XpoFooterModule,
    XpoLtlPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    XpoIconModule,
    XpoLtlPopoverModule,
    XpoAgGridModule,
    XpoGridToolbarModule,
    SdkUserPreferenceModule,
    SdkOffBillIncentiveModule,
    SdkPricingModule,
    SdkPricingWorkbenchModule,
    SdkCustomerModule,
    // CoreModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatDividerModule,
    MatListModule,
    MatProgressBarModule,
    XpoFiltersModule,
    XpoNgxGridModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: 'obi' }),
    AppRoutingModule, // Keep Last but one
    XpoPageNotFoundRoutingModule, // Keep Last
  ],
  providers: [DatePipe, DataApiService, NotificationService, AppRoleService],
  entryComponents: [ResultDialogComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
